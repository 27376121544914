

// set overlay of search dropdown the same style as form width/left

window.onload = function() {
    let dropdownSelect = document.querySelector('.m-dropdown') ;
    dropdownSelect?.addEventListener("click", function() {
        let overLay = document.querySelector('.p-multiselect-panel.p-component.m-search-dropdown-panel') ;
        let searchWidth = document.querySelector('.m-search-form-container>.m-search-input') ;
        overLay.style.width = searchWidth.offsetWidth + 'px' ;
        overLay.style.left = searchWidth.offsetLeft + 'px' ;
    });

    let loginModal = document.getElementById('loginModal') ;

    loginModal?.addEventListener('click' , function() {
        togglePassword() ;
    });
    togglePassword() ;

};


function togglePassword(){
    let passwordToggleMaskButton = document.getElementById('password_toggle_mask_button') ;

    passwordToggleMaskButton?.addEventListener('click' , function() {
        let passwordToggleMaskInput = document.getElementById('password_toggle_mask_input') ;

        if(passwordToggleMaskInput.type=='password'){
            passwordToggleMaskInput.setAttribute('type', 'text');
            passwordToggleMaskButton.classList.remove("pi-eye");
            passwordToggleMaskButton.classList.add("pi-eye-slash");
        }else{
            passwordToggleMaskInput.setAttribute('type', 'password');
            passwordToggleMaskButton.classList.remove("pi-eye-slash");
            passwordToggleMaskButton.classList.add("pi-eye");
        }
    });
}
